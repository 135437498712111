import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ownerInfo } from '../configs';

function Copyright() {
  return (
    <Grid container direction="row" alignItems="center" justify="space-evenly">
      <Grid item xs={11}>
        <Typography variant="caption" color="textSecondary" align="center">
          &ensp;Copyright ©&ensp;
          {new Date().getFullYear() > ownerInfo.foundingYear
            ? `${ownerInfo.foundingYear}-${new Date().getFullYear()}`
            : new Date().getFullYear()}
          <wbr />
          &ensp;{ownerInfo.shortNameEN ?? ownerInfo.legalNameEN}. All Rights Reserved.
          <wbr />
          &ensp;{ownerInfo.shortNameCN ?? ownerInfo.legalNameCN}&ensp;版权所有
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Copyright;
