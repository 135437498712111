import { memo } from 'react';
import { Match } from '@reach/router';
import SidebarItem from '../components/SidebarItem';
import { navItems } from '../configs';

function Sidebar() {
  return navItems.map((item) => {
    const { href, icon, labelCN, labelEN, key } = item;
    return (
      <Match path={href === '/' ? href : href.concat('/*')} key={key}>
        {(props) => (
          <SidebarItem
            href={href}
            primaryLabel={labelCN}
            secondaryLabel={labelEN}
            icon={icon}
            active={props.match}
          />
        )}
      </Match>
    );
  });
}

export default memo(Sidebar);
