import { memo, useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { navItems } from '../configs';

const useStyles = makeStyles((theme) => ({
  item: {
    '&:hover': {
      backgroundColor: theme.colors.sidebarHighLight,
      transition: 'background-color 400ms cubic-bezier(0.4, 0, 0.2, 1) 10ms',
      '& p': {
        color: '#FFFFFF',
        transition: 'color 400ms cubic-bezier(0.4, 0, 0.2, 1) 10ms'
      }
    }
  },
  title: {
    fontSize: 14
  },
  subTitle: { fontSize: 10 }
}));

function Nav() {
  const classes = useStyles();
  const location = window.location;
  const [tab, setTab] = useState(() => {
    let tabIndex = 0;
    navItems.forEach((item, index) => {
      if (window.location.pathname.includes(item.href)) {
        tabIndex = index;
      }
    });
    return tabIndex;
  });

  function onChange(ev, val) {
    ev.preventDefault();
    if (val !== tab) {
      navigate(navItems[val].href);
      setTab(val);
    }
  }

  useEffect(() => {
    navItems.forEach((item, index) => {
      if (window.location.pathname.includes(item.href)) {
        setTab(index);
      }
    });
  }, [location]);

  return (
    <Tabs
      value={tab}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      onChange={onChange}
    >
      {navItems.map((item) => {
        const { key, labelCN, labelEN } = item;
        return (
          <Tab
            className={classes.item}
            key={key}
            label={
              <>
                <Typography component="p" color="primary" className={classes.title}>
                  {labelCN}
                </Typography>
                <Typography component="p" color="primary" className={classes.subTitle}>
                  {labelEN}
                </Typography>
              </>
            }
          />
        );
      })}
    </Tabs>
  );
}

export default memo(Nav);
