import { useState, useEffect, memo } from 'react';
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NotFoundJpg from '../assets/NotFound.jpg';

const useStyles = makeStyles((theme) => ({
  container: { marginTop: theme.spacing(0.5) },
  img: {
    maxWidth: '100%',
    margin: 'auto',
    backgroundBlendMode: 'multiply',
    mixBlendMode: 'darken',
    zIndex: -1
  },
  font: { color: theme.palette.primary.main, '& span': { fontSize: theme.spacing(2) } },
  button: { textDecoration: 'underline', '&:hover': { textDecoration: 'underline' } }
}));

const NotFound = () => {
  const classes = useStyles();
  const [sec, setSec] = useState(6);

  useEffect(() => {
    const timer = setInterval(() => {
      setSec((v) => {
        if (v === 1) {
          navigate('/', { replace: true });
        }
        return v - 1;
      });
    }, [1000]);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Grid
      className={classes.container}
      container
      direction="column"
      justify="space-around"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={12}>
        <Typography className={classes.font} variant="h2" component="p">
          Page Not Found!
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center" alignItems="center">
        <Typography className={classes.font} variant="body1" component="p">
          {sec}秒钟后将带你返回首页...
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center" alignItems="center">
        <Typography className={classes.font} variant="body1" component="p">
          Redirect to Home Page in {sec} seconds...
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={7}>
        <img src={NotFoundJpg} alt="NotFound" className={classes.img} />
      </Grid>
    </Grid>
  );
};

export default memo(NotFound);
