import { memo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Nav from '../components/Nav';
import Contact from '../components/Contact';
import Copyright from '../components/Copyright';
import Sidebar from './Sidebar';
import { ownerInfo, headerConfigs } from '../configs';

import logoSrc from '../assets/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fbfbfb',
    padding: theme.spacing(0.5, 2, 0.25, 2),
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    position: 'fixed',
    zIndex: 2,
    top: theme.spacing(0.25),
    left: theme.spacing(2)
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    // paddingTop: theme.spacing(4),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  copyright: {
    position: 'absolute',
    bottom: theme.spacing(2)
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    ...theme.mixins.toolbar
  }
}));

function Header() {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <AppBar className={classes.root} color="transparent" elevation={1}>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={sidebarOpen}
          onClose={() => {
            setSidebarOpen(false);
          }}
          ModalProps={{ keepMounted: true }}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbarIcon}>
            <img src={logoSrc} alt="logo" style={{ maxWidth: '64%', margin: 'auto' }} />
          </div>
          <Divider />
          <Sidebar />
          <Grid container justify="center" className={classes.copyright}>
            <Grid item xs={10}>
              <Contact />
            </Grid>
            <Grid item xs={12}>
              <Copyright />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            setSidebarOpen(true);
          }}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} md={3} lg={3}>
          <Hidden smDown>
            {headerConfigs.showLogo ? (
              <img src={logoSrc} alt="logo" style={{ maxWidth: '10rem', margin: 'auto' }} />
            ) : (
              <>
                <Typography variant="h4" color="primary" component="p">
                  {ownerInfo.legalNameCN}
                </Typography>
                <Typography variant="caption" color="primary" component="p">
                  {ownerInfo.legalNameEN}
                </Typography>
              </>
            )}
          </Hidden>
          <Hidden mdUp>
            <Typography variant="h4" color="primary" component="p">
              {ownerInfo.legalNameCN}
            </Typography>
            <Typography variant="caption" color="primary" component="p">
              {ownerInfo.legalNameEN}
            </Typography>
          </Hidden>
        </Grid>
        <Hidden smDown>
          <Grid item xs={10} md={7} lg={7}>
            <Nav />
          </Grid>
          {headerConfigs.showContacts && (
            <Grid item xs={2}>
              <Contact />
            </Grid>
          )}
        </Hidden>
      </Grid>
    </AppBar>
  );
}

export default memo(Header);
