import { memo, cloneElement } from 'react';
import { Router } from '@reach/router';
import NotFound from './NotFound';
import { navItems } from '../configs/app';

function Content() {
  return (
    <>
      <Router primary={false}>
        {navItems.map((item) => {
          const { component, href, key } = item;
          return cloneElement(component, { path: href, key });
        })}
        <NotFound default />
      </Router>
    </>
  );
}

export default memo(Content);
