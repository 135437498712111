import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        whiteSpace: 'nowrap',
        minWidth: 'max-content',
        fontWeight: 600,
        fontFamily: 'Dinpro',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16
      }
    }
  },
  palette: {
    primary: {
      light: '#33ABB8',
      main: '#00A2A9',
      dark: '#0099A7'
    },
    secondary: {
      light: '#FF5722',
      main: '#FF5630',
      dark: '#F4511E'
    }
  },
  colors: {
    sidebarHighLight: '#00A2A950',
    sidebarActive: '#00A2A918',
    shadowHighLight: '#00A2A96C',
    tableRowHighLight: '#00A2A912',
    tableRowHighLightSecondary: '#FF563048'
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: 32,
      fontWeight: 600
    },
    h2: {
      fontSize: 27,
      fontWeight: 600
    },
    h3: {
      fontSize: 24,
      fontWeight: 600
    },
    h4: {
      fontSize: 21,
      fontWeight: 600
    },
    body1: {
      fontSize: 16,
      fontWeight: 400
    },
    body2: {
      fontSize: 16,
      fontWeight: 600
    }
  }
});

const withTheme = (Component) => {
  const CustomComponent = (props) => (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Component {...props} />
    </ThemeProvider>
  );

  return CustomComponent;
};

export default withTheme;
