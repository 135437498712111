import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Copyright from '../components/Copyright';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(0.5, 0), position: 'fixed', bottom: 0, width: '100%' }
}));

function Footer() {
  const classes = useStyles();
  return (
    <Hidden mdDown>
      <Paper className={classes.root} square variant="outlined">
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Copyright />
        </Grid>
      </Paper>
    </Hidden>
  );
}

export default memo(Footer);
