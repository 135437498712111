import Loadable from '@loadable/component';
import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Splash from '../components/Splash';

const AsyncHome = Loadable(() => import('../pages/Home'), { fallback: <Splash /> });

const AsyncJoin = Loadable(() => import('../pages/Join'), { fallback: <Splash /> });

const AsyncPartners = Loadable(() => import('../pages/Partners'), { fallback: <Splash /> });

const AsyncAbout = Loadable(() => import('../pages/About'), { fallback: <Splash /> });

export const navItems = [
  {
    key: 'home',
    href: '/',
    labelEN: 'Home',
    labelCN: '首页',
    icon: <HomeIcon />,
    component: <AsyncHome />
  },
  {
    key: 'partners',
    href: 'partners',
    labelEN: 'Partners',
    labelCN: '合作伙伴',
    icon: <BusinessIcon />,
    component: <AsyncPartners />
  },
  {
    key: 'team',
    href: 'team',
    labelEN: 'Team',
    labelCN: '关于团队',
    icon: <GroupIcon />,
    component: <AsyncAbout />
  },
  {
    key: 'join',
    href: 'join-us',
    labelEN: 'Join us',
    labelCN: '加入我们',
    icon: <PersonAddIcon />,
    component: <AsyncJoin />
  }
];

export const headerConfigs = {
  showLogo: true, // if false we will render the legal name
  showContacts: true
};
