import { memo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  activeItem: {
    backgroundColor: theme.colors.sidebarActive
  },
  item: {
    '&:hover': {
      backgroundColor: theme.colors.sidebarHighLight
    }
  },
  active: {
    color: theme.palette.primary.main,
    borderRadius: '5px',
    opacity: '1',
    fontWeight: 600
  }
}));

function Sidebar(props) {
  const { active, href, icon, primaryLabel, secondaryLabel } = props;
  const classes = useStyles();
  return (
    <div>
      <Link to={href} className={classes.link}>
        <ListItem button className={clsx(classes.item, active && classes.activeItem)}>
          <ListItemIcon className={clsx(active && classes.active)}>{icon}</ListItemIcon>

          <ListItemText
            classes={{
              primary: clsx(active && classes.active),
              secondary: clsx(active && classes.active)
            }}
            primaryTypographyProps={{ color: 'textPrimary', variant: 'subtitle2' }}
            secondaryTypographyProps={{ color: 'textPrimary', variant: 'caption' }}
            primary={primaryLabel}
            secondary={secondaryLabel}
          />
        </ListItem>
      </Link>
    </div>
  );
}

Sidebar.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  primaryLabel: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
};

Sidebar.defaultProps = {
  active: false,
  secondaryLabel: null
};

export default memo(Sidebar);
