import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email';

export const ownerInfo = {
  legalNameCN: '深圳麟勋科技有限公司',
  shortNameCN: '麟勋科技',
  legalNameEN: 'Shenzhen Rinxun Technology Co.,Ltd.',
  shortNameEN: 'Rinxun',
  foundingYear: 2021
};

export const ownerContacts = [
  { title: 'Github', link: 'https://github.com/Rinxun', icon: <GitHubIcon /> },
  { title: 'Email', link: 'mailto:hello@rinxun.com', icon: <EmailIcon /> }
];

export const ownerIntro = {
  CN: {
    title: '简介',
    content: [
      '深圳麟勋科技有限公司（以下简称「麟勋科技」）成立于2021年6月，是由原深圳力库科技有限公司研发团队组建而成。',
      '在全球新冠病毒疫情大流行的环境下，麟勋科技研发团队利用互联网协同办公，同美国、印度尼西亚、印度、尼日利亚等国家/地区的研发人员合作，提供优质高效的软件技术服务。'
    ]
  },
  EN: {
    title: 'Introduction',
    content: [
      'Shenzhen Rinxun Technology Co.,Ltd. ("Rinxun") is founded in June 2021, it is formed by the original R&D team of Shenzhen Liku Technology Ltd..',
      'In the global COVID-19 pandemic environment, Rinxun R&D team uses the Internet to collaborate and work with developing engineers in United States, Indonesia, India, Nigeria, and other countries/areas to provide high-quality and efficient software technical services.'
    ]
  }
};
