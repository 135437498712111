import { memo, cloneElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { ownerContacts } from '../configs';

function Contact() {
  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      {ownerContacts.map((c) => {
        const { link, title, icon } = c;
        return (
          <Grid item key={link}>
            <Tooltip title={<Typography variant="caption">{title}</Typography>} arrow>
              <Link color="primary" href={link} target="__blank">
                {cloneElement(icon)}
              </Link>
            </Tooltip>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default memo(Contact);
