import { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import logoSrc from '../assets/logo.png';

const useStyles = makeStyles(() => ({
  container: { marginTop: '32vh' },
  item: { textAlign: 'center' }
}));

const Splash = (props) => {
  const classes = useStyles(props);

  return (
    <Grid
      container
      classes={{ container: classes.container }}
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={9} sm={8} md={7} lg={6} className={classes.item}>
        <img src={logoSrc} alt="logo" width="64%" height="100%" />
      </Grid>
    </Grid>
  );
};

export default memo(Splash);
